<template>
    <div>
        <v-main class="mb-0" v-for="item in menuTree" v-bind:key="item.label">
            <div class="mb-3 titleMenuItens">
                {{ item.label }}
            </div>
            <v-col class="d-flex">
                <v-expansion-panels flat>
                    <v-expansion-panel
                        v-for="area in item.areas"
                        v-bind:key="area.label"
                        class="mb-3 pr-3"
                    >
                        <v-expansion-panel-header class="text-left pa-0 mb-3">
                            <v-icon color="altPrimary" class="v-icon-menu">{{ area.icon }}</v-icon>
                            <span class="expansion-title">
                                {{ area.label }}
                            </span>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content
                            v-for="link in area.links"
                            v-bind:key="link.label"
                        >
                            <router-link
                                class="text-decoration-none"
                                :to="link.to"
                                :title="link.label"
                            >
                                <span>{{ link.label }}</span>
                            </router-link>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col> </v-main
        >
    </div>
</template>

<script>
import menuTree from './_menuTree';

export default {
    name: 'Menu',
    data: () => ({
        showMenu: true,
        menuTree,
    }),
};
</script>

<style style="scss" scoped>
@import './Menu.scss';
</style>

<template>
    <v-main :class="{ 'hidden-mobile': !showMenu }" class="menuWidget">
        <img class="rounded-lg widgetImg" :src="imgUrl" />

        <div class="text-center">
            <p class="prodindicatorRegion">Produto</p>
            <p class="setProdName">{{ productName }}</p>
        </div>

        <v-col class="d-flex mt-n5 mb-6">
            <v-btn
                class="buttonColor secondary no-letter-spacing"
                small
                outlined
                @click="dialogSelectProduct = true"
            >
                Alterar Produto
                <span>
                    <v-icon size="17" class="iconButtonProd">mdi-chevron-down</v-icon>
                </span>
            </v-btn>
        </v-col>
        <Menu />
        <v-dialog content-class="modal-default modal-default-small" v-model="dialogSelectProduct">
            <header>
                Alterar Produto
                <v-icon
                    role="button"
                    title="Fechar"
                    @click="dialogSelectProduct = false"
                    color="altPrimary"
                    >mdi-close-thick</v-icon
                >
            </header>

            <v-main>
                <section class="portals-container">
                    <v-row
                        class="mb-2 mt-3 portal-item"
                        align="center"
                        justify="center"
                        v-for="portal in originators"
                        v-bind:key="portal.key"
                    >
                        <v-col cols="6" md="6" class="img-industry">
                            <img :src="getImgUrl(portal.apiPhotoUrl)" />
                        </v-col>

                        <v-col cols="12" sm="6" md="6" align="right" justify="center">
                            <v-menu
                                content-class="elevation-1"
                                bottom
                                origin="center center"
                                transition="scale-transition"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        class="buttonColorPortal"
                                        v-bind="attrs"
                                        :title="portal.nome"
                                        v-on="on"
                                        outlined
                                        >Selecione o Produto<span
                                            ><v-icon size="17" class="iconButtonProd"
                                                >mdi-chevron-down</v-icon
                                            ></span
                                        ></v-btn
                                    >
                                </template>

                                <v-list class="mt-9 custom-menu">
                                    <v-list-item
                                        @click="selectProduct(item, portal)"
                                        v-for="(item, i) in portal.products"
                                        :key="i"
                                    >
                                        <v-list-item-title>{{
                                            item.register.name
                                        }}</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-col>
                        <hr />
                    </v-row>
                    <footer align="right" class="mt-6"></footer>
                </section>
            </v-main>
        </v-dialog>
    </v-main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Menu from './Menu';
import { getImgUrl } from '@/helpers/images';

export default {
    name: 'Sidebar',
    components: {
        Menu,
    },
    data: () => ({
        showMenu: false,
        getImgUrl,
        productName: '',
        products: [],
        originator: {},
        imgUrl: '',
        dialogSelectProduct: false,
    }),
    methods: {
        ...mapActions('base', {
            updateCurrentProduct: 'updateCurrentProduct',
            getOriginators: 'getOriginators',
        }),
        setImgUrl() {
            this.imgUrl = this.getImgUrl(this.apiPhotoUrl);
        },
        updateOriginatorImage() {
            const originator = this.originators.find(el => {
                return el.id === this.selectedProduct.idMaster;
            });
            if (originator) {
                this.originator = originator;
                this.setImgUrl(this.selectedProduct.idMaster);
            }
        },
        async selectProduct(product) {
            await this.$dialog.confirm({
                text: 'Deseja realmente alterar? Você será reiniciado para a tela inicial',
                title: 'Alterar produto',
                actions: {
                    false: {
                        text: 'Cancelar',
                        color: 'danger',
                    },
                    true: {
                        text: 'Sim',
                        color: 'secondary',
                        handle: () => {
                            this.updateCurrentProduct({ ...product, parent: this.originator });
                            this.productName = this.selectedProduct.register.name;
                        },
                    },
                },
            });
        },
    },
    computed: {
        ...mapGetters('base', {
            selectedProduct: 'selectedProduct',
            originators: 'originators',
            menuOnMobile: 'menuOnMobile',
        }),

        apiPhotoUrl() {
            return this.originator.apiPhotoUrl;
        },
    },
    async created() {
        await this.getOriginators();
        this.productName = this.selectedProduct.register.name;
    },
    watch: {
        selectedProduct() {
            if (this.$router.history.current.path.indexOf('/admin/dashboard') !== 0) {
                this.$router.push(`/admin/dashboard`);
            }
            this.updateOriginatorImage();
            this.dialogSelectProduct = false;
        },
        menuOnMobile(newValue) {
            this.showMenu = newValue;
        },
        originators() {
            this.updateOriginatorImage();
        },
    },
};
</script>

<style lang="scss" scoped>
@import './Sidebar.scss';
</style>

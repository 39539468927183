<template>
    <v-app-bar class="navBarStyle">
        <div class="row align-center">
            <router-link to="/admin/dashboard" title="Dashboard">
                <img
                    alt="Farm Investimentos"
                    class="logoImg logoImg-desktop"
                    src="@/assets/logo-light.png"
                    transition="scale-transition"
                />
            </router-link>
            <router-link to="/admin/dashboard" title="Dashboard">
            <img
                alt="Farm Investimentos"
                class="logoImg logoImg-mobile"
                src="@/assets/logo-sm.png"
                transition="scale-transition"
            />
            </router-link>
            <div class="toggleStyle" @click="isMenu">
                <v-icon size="20" class="toggleLineIcon" color="white">mdi-menu</v-icon>
            </div>
        </div>

        <v-spacer></v-spacer>
        <div class="pt-3 mr-n2">
            <v-menu left origin="top center" transition="scale-transition" class="mt-16">
                <template v-slot:activator="{ on }">
                    <v-btn depressed class="buttonColorNav ml-2 mt-3 pa-0" v-on="on"
                        ><v-badge v-on="on" color="orange " content="6">
                            <v-icon color="white">mdi-bell-outline</v-icon>
                        </v-badge></v-btn
                    >
                </template>

                <v-list class="vlistNotification">
                    <div class="pl-4 pt-1 pb-2">Nofiticações</div>
                    <v-main class="notificationBox">
                        <v-list-item-group v-model="selected" class="listProfile" multiple>
                            <template v-for="(item, index) in items">
                                <v-list-item :key="item.title">
                                    <template>
                                        <v-list-item-avatar>
                                            <img
                                                src="https://cdn.vuetifyjs.com/images/john.jpg"
                                                alt="John"
                                            />
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title
                                                v-text="item.title"
                                            ></v-list-item-title>

                                            <v-list-item-subtitle
                                                v-text="item.subtitle"
                                            ></v-list-item-subtitle>
                                            <v-row>
                                                <v-col>
                                                    <div class="d-flex">
                                                        <v-icon
                                                            size="15px"
                                                            style="padding-top: 2px; color: grey"
                                                        >
                                                            mdi-clock-outline
                                                        </v-icon>
                                                        <v-list-item-subtitle
                                                            style="
                                                                padding-left: 5px;
                                                                font-size: 12px;
                                                                margin-top: -6px;
                                                            "
                                                            v-text="item.time"
                                                            class="pt-2"
                                                        ></v-list-item-subtitle>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </v-list-item-content>
                                    </template>
                                </v-list-item>

                                <v-divider v-if="index < items.length - 1" :key="index"></v-divider>
                            </template>
                        </v-list-item-group>
                    </v-main>
                </v-list>
            </v-menu>
        </div>
        <div>
            <v-menu left transition="scale-transition">
                <template offset-y v-slot:activator="{ on }">
                    <v-avatar class="avatarStyle">
                        <img
                            v-on="on"
                            style="border: #78beb1 3px solid"
                            src="https://cdn.vuetifyjs.com/images/john.jpg"
                            alt="John"
                        />
                    </v-avatar>
                    <v-btn depressed class="buttonColorNav" v-on="on"
                        ><span class="nameUserStyle">{{ profile ? profile.nome : null }}</span
                        ><span
                            ><v-icon size="17" color="white" class="ml-1 arrowIconUser"
                                >mdi-chevron-down</v-icon
                            ></span
                        ></v-btn
                    >
                </template>
                <v-list nav dense class="mt-0" style="width: 170px">
                    <v-list-item-group v-model="selectedItem" class="listProfile">
                        <!-- Meu Perfil -->
                        <v-list-item>
                            <v-list-item-icon class="mr-4">
                                <v-icon>mdi-account</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>Meu Perfil</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <!-- Cessões -->
                        <v-list-item>
                            <v-list-item-icon class="mr-4">
                                <v-icon>mdi-wallet-outline</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>Cessões</v-list-item-title>
                            </v-list-item-content>

                            <v-list-item-action>
                                <div
                                    style="
                                        font-size: 10px;
                                        color: white;
                                        background-color: #00b493;
                                        padding: 2px;
                                        border-radius: 3px;
                                    "
                                >
                                    11
                                </div>
                            </v-list-item-action>
                        </v-list-item>
                        <!-- Configurações -->
                        <v-list-item>
                            <v-list-item-icon class="mr-4">
                                <v-icon>mdi-hammer-wrench</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>Configurações</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <!-- Sair -->
                        <v-list-item @click="logout">
                            <v-list-item-icon class="mr-4">
                                <v-icon color="warning">mdi-location-exit</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title style="color: orange">Sair</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-menu>
        </div>
    </v-app-bar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
    name: 'Navbar',
    data: () => ({
        group: null,
        onOffMenu: false,
        selectedItem: 0,
        itemsTransition: [
            { text: 'Meu Perfil', icon: 'mdi-account', number: '' },
            { text: 'Cessões', icon: 'mdi-wallet-outline', number: '11' },
            { text: 'Configurações', icon: 'mdi-hammer-wrench', number: '' },
            { text: 'Sair', icon: 'mdi-location-exit', number: '' },
        ],
        selected: [2],
        items: [
            {
                time: '5 de Fevereiro 2020',
                subtitle: `I'll be in your neighborhood doing errands this weekend. Do you want to hang out?`,
                title: 'Ali Connors',
            },
            {
                time: '5 de Fevereiro 2020',
                subtitle: `Wish I could come, but I'm out of town this weekend.`,
                title: 'me, Scrott, Jennifer',
            },
            {
                time: '5 de Fevereiro 2020',
                subtitle: 'Do you have Paris recommendations? Have you ever been?',
                title: 'Sandra Adams',
            },
            {
                time: '5 de Fevereiro 2020',
                subtitle: 'Have any ideas about what we should get Heidi for her birthday?',
                title: 'Trevor Hansen',
            },
            {
                time: '5 de Fevereiro 2020',
                subtitle: 'We should eat this: Grate, Squash, Corn, and tomatillo Tacos.',
                title: 'Britta Holt',
            },
        ],
    }),
    computed: {
        ...mapGetters('auth', {
            profile: 'profile',
            profileRequestStatus: 'profileRequestStatus',
        }),
    },
    methods: {
        ...mapActions('auth', {
            submitLogout: 'logout',
        }),
        ...mapActions('base', {
            toggleMenuOnMobile: 'toggleMenuOnMobile',
        }),
        isMenu() {
            this.toggleMenuOnMobile();
        },
        async logout() {
            await this.$dialog.confirm({
                text: 'Deseja realmente sair?',
                title: 'Sair',
                actions: {
                    false: {
                        text: 'Cancelar',
                        color: 'danger',
                    },
                    true: {
                        text: 'Sim',
                        color: 'secondary',
                        handle: () => {
                            this.submitLogout();
                            this.$router.push({ path: '/login' });
                        },
                    },
                },
            });
        },
    },
};
</script>
<style lang="scss" scoped>
@import './styles.scss';
</style>
